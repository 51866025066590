.Subscription {
  padding: 1rem 0.25rem;
}
.CardIconSub {
  display: initial;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 1.8rem;
  background-color: #c0c0c0;
}
.CardIconSubActive {
  background-color: #ffd700;
  border: 3px solid #fc00d2;
  color: #fc00d2;
}
.SubscriptionDetailList {
  margin-bottom: 1rem;
  display: inline-block;
}
.SubscriptionDetailItemSpan {
  color: #fc00d2;
  text-decoration: underline;
}
.SubscriptionPayBtn {
  margin-right: 1rem;
}
.Active {
  border: 2px solid #fc00d2;
  border-radius: 10px;
}
