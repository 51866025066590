.BreadCrumb {
  background: transparent;
}
.BreadCrumb ol {
  background: transparent;
}

.SubscriptionItemWrapper {
  padding: 0.15rem 0.75rem;
  font-size: 2rem;
  background-color: #c0c0c0;
}
.SubActive {
  font-weight: bold;
  border: 3px solid #fc00d2;
  color: #fc00d2;
  background-color: #ffd700;
}
.SubscriptionItemDetail {
  padding-top: 1rem;
  margin-left: 1rem;
}

.DepositWrapper {
}

.SubscriptionCountWrapper {
  margin-top: 1.5rem;
}

.SubscriptionCountText,
.DepositText {
  margin-bottom: 0;
  font-size: 0.9rem;
}
.SubscriptionCountSpan,
.DepositSpan {
  font-style: italic;
  color: #fc00d2;
  float: right;
}
