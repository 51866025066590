.Cart__Wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.Cart__Empty {
  margin: 0 auto;
  text-align: center;
}
.Cart__Items {
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.Cart__Summary {
  width: 30%;
}
.Cart__SummarySpan {
  color: #fc00d2;
  text-decoration: underline;
}
