.CustomToggleIcon {
}

.DropDown {
  margin-left: 20px;
  font-style: italic;
  padding-top: 0.6rem;
}

.DropDown a {
  color: #000;
}

.DropDownItem {
}
