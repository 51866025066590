.ProfileDepositWrapper {
}
.ProfileDeposit__ChipWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.ProfileDeposit__Chip {
  cursor: pointer;
  display: inline-block;
  padding: 6px 20px;
  border-radius: 25px;
  background-color: #fc00d2;
  color: #fff;
}
.ProfileDeposit__Current {
  color: #fc00d2;
}
