.ProductList {
  width: 100%;
}
.CarouselItem img {
  display: block;
  margin: auto;
  max-width: 100%;
  visibility: visible;
}

.ProductBody {
  padding: 0.15rem;
  text-align: center;
}
.ProductBody__Title {
  font-size: 1rem;
  color: #000;
}
.ProductBody__Text {
  font-size: 0.85rem;
}
.ProductBody__Cart {
  margin: 0.5rem 0;
  padding: 0 0.25rem;
}

.Pagination {
  margin: 0 auto;
}
