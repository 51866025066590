.NavBarWrapper {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}
.NavBar {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0;
}

.Link {
  text-decoration: inherit;
  color: inherit;
}
.Link:hover {
  text-decoration: inherit;
  color: inherit;
}
.NavBar__GrayLink {
  color: #000;
}
.NavBar__GrayLink > a {
  color: #000;
}
.NavBar__GrayLink > a:hover {
  color: #fc00d2;
}
