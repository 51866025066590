.Accordion {
  width: 250px;
}
.AccordionItem {
  cursor: pointer;
  border: none;
}
.AccordionItemTitle {
  font-weight: 400;
  padding: 0.25rem;
  background-color: #f0f0f0;
  border-bottom: none;
}
.AccordionItemBody {
  padding: 0 0 0 0.25rem;
  background-color: #f0f0f0;
}
.AccordionItemIcon {
  margin-left: 0.5rem;
}
.AccordionItemBody label {
  cursor: pointer;
}
input[type='checkbox'] {
  visibility: hidden;
}
label {
  cursor: pointer;
}
input[type='checkbox'] + label:before {
  border: 1px solid #333;
  border-radius: 5px;
  content: '\00a0';
  display: inline-block;
  height: 16px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
input[type='checkbox']:checked + label:before {
  background: #fff;
  color: #333;
  font-size: 10px;
  font-weight: bold;
  content: '\2713';
  text-align: center;
}
input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

input[type='checkbox']:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
