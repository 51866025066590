/* customizations React Bootstrap Slider */
$theme-colors: (
  'primary': #fc00d2,
);
@import '~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss';

// Override default variables before the import
$body-bg: #f0f0f0;
// change the theme
$primary: #fc00d2;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tiler img {
  display: block;
  margin: auto;
  max-width: 100%;
  visibility: visible;
}
.tiler .cell-grid {
  background-position: center center;
  margin: auto;
  position: fixed;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
  z-index: 10;
  max-width: 1000px;
  max-height: 600px;
  perspective: 30px;
}
.nav-link.active {
  color: $primary !important;
}

$ig-blue: #fc00d2 !important;
@import '~react-image-gallery/styles/scss/image-gallery.scss';
