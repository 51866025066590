.product__buttons .dropdown-menu.show {
  width: 100%;
}
.dropdown-menu.show {
  left: auto;
  right: 0;
}
.react-tiny-popover-container {
  z-index: 100;
  margin-top: 200px;
}
.dropdown-item.active {
  background-color: #fc00d2;
}
.active.dropdown > a {
  color: #fc00d2;
}

.svg-inline--fa.fa-search.fa-w-16 {
  width: 20%;
  margin: 0;
  background-color: #f0f0f0;
}

/*Preloader start*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fc00d2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fc00d2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Preloader end*/
