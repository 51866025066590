.PopoverArrowContainer > div {
  top: 50%;
}
.PopoverArrow {
  opacity: 0.1;
  top: 50%;
}
.PopoverImage {
  width: 30vw;
  z-index: 100;
}
