.Tabs {
  flex-direction: row;
  justify-content: space-around;
}

.Tabs a {
  width: 50%;
  flex: 1 1 auto;
  text-align: center;
}

.Tab button {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.spanLink {
  color: #fc00d2;
}

.spanLink:hover {
  text-decoration: underline;
}
