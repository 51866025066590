.ShopFront {
  margin: 0 auto;
}
.SearchWrapper {
  margin-bottom: 20px;
}
.BodyWrapper {
  display: flex;
  flex-wrap: nowrap;
}
.CategoryList {
}
