.SearchBarWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 10px 0;
  border-bottom: 1px solid gray;
  font-size: 0.9rem;
}
.SearchBar {
  width: 250px;
}
.SearchBarForm {
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: none;
  border-left: 1px solid;
  border-color: #000;
  border-radius: 3px;
  flex-direction: row;
  flex-wrap: nowrap;
}
.SearchBarForm.Active {
  border-color: #fc00d2;
}
.SearchBarInput {
  border: none;
  background-color: #f0f0f0;
  padding-left: 0.5rem;
  height: 40px;
  width: calc(100% - 24px) !important;
}
.SearchItemIcon {
  margin-left: 0.5rem;
}

.SortingBarWrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.SortingBarItem {
  flex: 0 1 auto;
  margin: 0 10px;
}
.IconWrapper {
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
}
