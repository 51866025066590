.OrderWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.OrderSubscriptionItem {
  display: initial;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 1.8rem;
}
.OrderPriceSpan {
  color: #fc00d2;
  font-weight: bold;
}
